<template>
	<AccountSetting />
</template>

<script>
import AccountSetting from './account-setting/AccountSetting'
export default {
	name: 'General',
	components: { AccountSetting },
}
</script>